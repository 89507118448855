@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway:400,600,700&display=swap");
@font-face {
  font-family: League-Gothic;
  font-weight: normal;
  src: url("./assets/fonts/leaguegothic-regular-webfont.eot"),
    url("./assets/fonts/leaguegothic-regular-webfont.ttf"),
    url("./assets/fonts/leaguegothic-regular-webfont.woff");
}
@font-face {
  font-family: Boxfont-Round;
  font-weight: normal;
  src: url("./assets/fonts/Boxfont\ Round.ttf");
}
body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #848484;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.header-container-wrapper .page_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.page_container {
  width: 100%;
  padding-right: 55px;
  padding-left: 55px;
  margin-right: auto;
  margin-left: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}
h1 {
  font-size: 50px;
}
h2 {
  font-family: League-Gothic;
  font-size: 50px;
}
h3 {
  font-family: League-Gothic;
  font-size: 35px;
}
h4 {
  font-size: 35px;
}
h4 {
  font-family: League-Gothic;
  font-size: 30px;
}
h4 span,
h2 span,
h1 span,
h3 span {
  color: #d80101;
}

ul.unstyle {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  color: #000;
}

.navbar-brand img {
  max-width: 300px;
  width: 100%;
}
.navbar-light .navbar-nav .nav-link {
  color: #000;
  font-family: Raleway;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin: 0 1.2em;
  padding: 0.618em 0;
}

/* header start  */
div#navbarsExampleDefault a.nav-link {
  color: #4b5561;
  text-transform: capitalize;
  font-weight: 600;
}
div#navbarsExampleDefault li.nav-item {
  margin: 0 30px;
}
div#navbarsExampleDefault li.nav-item:last-child {
  margin-right: 0;
}
div#navbarsExampleDefault li.nav-item.active a.nav-link {
  color: #ff0004;
}
.header-container-wrapper {
  padding: 2px 0;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #fc4b4b;
}
.navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
/* header end */

.video_section img {
  max-width: 510px;
  width: 100%;
}
/* home page start  */
.screen-slider .heading-sec h3 {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin-bottom: 30px;
}
h3.heading-loto {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 26px;
  color: #d80101;
}
h3.heading-loto span {
  font-weight: normal;
  font-family: Boxfont-Round;
}
.app_work_section p.card-text {
  color: #47525e;
}
.banner-img {
  height: calc(100vh - 76px);
}
.custom_caption {
  bottom: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  right: auto;
  left: 50%;
  width: 100%;
}
.card_section {
  text-align: center;
  padding: 50px 0 20px;
}
.carousel_container {
  width: 90%;
  margin: 0 auto;
}
.banner-slider.left ul.unstyle {
  text-align: left;
  position: relative;
  z-index: 9;
}
.banner-slider.center.text-center {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  transform: translateY(-50%);
}
.video_section {
  position: absolute;
  top: 47%;
  left: 49.8%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  overflow: hidden;
}
.banner-img.video {
  background: #242632;
}
.content.first .banner-slider.left > ul.unstyle > li:first-child {
  margin-bottom: 30px;
}
.banner-slider.left ul.unstyle li {
  display: flex;
}
.carousel-indicators li {
  width: 50px;
  opacity: 1;
  border: 0;
}
.carousel-indicators li.active {
  border: 0;
  background: #fc4b4b;
}
.banner-slider.left ul.unstyle li h1 {
  color: #fff;
  margin: 0;
  padding-left: 60px;
  font-family: Boxfont-Round;
}
.carousel-indicators {
  margin-bottom: 40px;
}
.banner-slider.left > ul.unstyle > li:first-child {
  margin-bottom: 50px;
}
.banner-slider.left ul.unstyle li i {
  display: inline-block;
  padding: 20px 0 0;
}
.banner-slider.left ul.unstyle li ul li {
  margin: 0 20px;
}
.banner-slider.left ul.unstyle li h2 {
  color: #d80101;
  padding: 0 0 0 100px;
}
.banner-img.lst {
  background-size: cover;
  background-position: center;
}
.inline-img li {
  display: inline-block;
}

.inline-img {
  display: flex;
  flex-wrap: nowrap;
}

.banner-slider.left > ul.unstyle > li {
  margin-bottom: 30px;
}
.custom_caption.carousel-caption .col-md-6.content {
  display: flex;
  align-items: center;
}
.banner-slider.left {
  padding: 0 20px;
}
.banner-slider.left ul.unstyle ul.unstyle.inline-img {
  padding-left: 30px;
}
.banner-slider.right {
  text-align: right;
}

.banner-slider.right img {
  margin: 70px;
}
.carousel-inner .carousel-item img.d-block.w-100 {
  max-height: 570px;
  object-fit: cover;
}
.card_section .card {
  border: 0;
}

.card_section .card img.card-img-top {
  max-width: 100px;
  margin: 0 auto;
}

.card_section .card:last-child img.card-img-top {
  max-width: 58px;
  margin-top: 10px;
}

.card_section .card-body {
  padding: 1.25rem 2rem;
}
.card_section .img-wrapper {
  min-height: 115px;
}
.app_work_section {
  background: #d8d8d8;
  padding: 40px 0;
  display: none;
}
.app_work_section .img-left {
  text-align: center;
}

.h_card {
  display: flex;
  margin-bottom: 70px;
}
.h_card .left_col {
  min-width: 60px;
  text-align: center;
}
.h_card .left_col img.card-img {
  max-width: fit-content;
}

footer.footer_wrapper small {
  font-size: 10px;
}
.app_work_section .card_wrapper {
  padding: 30px 0 0;
}

.app_work_section .slide1 {
  padding: 0 0 0 110px;
}
.app_work_section .right_col .card-body {
  padding: 0 0 0 20px;
}

.app_work_section .right_col .card-body h4.card-title {
  margin: 0;
}

.app_work_section .slide1 h4.title {
  font-family: "Lato", sans-serif;
  font-size: 35px;
}

.app_work_section .card_wrapper .h_card:last-child img.card-img {
  max-width: fit-content;
  max-width: 30px;
}
/* .feature_section {
  padding: 0px 0 20px;
} */
.feature_section h3 {
  text-align: center;
  text-transform: capitalize;
}

footer.footer_wrapper .font-b small {
  font-size: 13px;
}

.feature_section .card {
  border: 0;
  text-align: center;
  margin-bottom: 30px;
}

.feature_section .card-deck {
  margin-top: 70px;
  margin-right: 0;
  margin-left: 0;
}
.feature_section .img-wrapper {
  text-align: center;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.feature_section h3.title {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  padding: 0 10px;
}
.screen-slider {
  background: #d8d8d8;
  padding: 30px 0 0;
}
.screen-slider .heading-sec {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 10px;
}
.downloadapp_section .right_sec h5 {
  color: #d80101;
}
.downloadapp_section {
  padding: 50px 0;
}

.downloadapp_section .right_sec {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.homeslider ul.slick-dots {
  bottom: 20px;
}
.downloadapp_section .img-right_col {
  text-align: center;
}
footer.footer_wrapper {
  text-align: center;
  background: #000;
  color: #fff;
  padding: 20px 20px;
}

footer.footer_wrapper ul.unstyle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

footer.footer_wrapper ul.unstyle li {
  padding: 0 20px;
}
.carousel-control-next,
.carousel-control-prev {
  display: none;
}
.banner-right-sec {
  position: relative;
}

.banner-right-sec > div {
  position: static;
}

.banner-right-sec > div.slide1 {
  top: -80px;
  right: 0;
}

.banner-right-sec > div.slide2 {
  top: 40px;
  right: 140px;
  z-index: 1;
}
.banner-right-sec > div.slide3 {
  right: 0;
}
.banner-right-last {
  text-align: right;
}
.body-container-wrapper .row {
  margin-right: 0;
  margin-left: 0;
}
.screen-slide .slick-slider.homeslider.slick-initialized {
  width: 100%;
  margin: 0 auto;
  max-width: 224px;
}
.screen-slide {
  margin: 60px 0 0;
  padding: 30px 0;
  position: relative;
}
.screen-slide button.slick-arrow {
  display: none !important;
}

.phn_screen {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-top: -12px;
  z-index: 1;
  margin-left: 2px;
}
.slick-slide img {
  margin: auto;
  max-width: 221px;
  transition: all 0.5s ease;
}
.banner-right-sec img {
  max-width: 680px;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

button.slick-arrow.slick-prev {
  background: url(./assets/img/slider/arrowleft.png);
}
button.slick-arrow.slick-next {
  background: url(./assets/img/slider/arrowright.png);
}
button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  opacity: 1;
  z-index: 9;
  width: 40px;
  height: 40px;
  background-size: cover !important;
}
.col-6.content {
  display: flex;
  align-items: center;
}
.downloadapp_section .inline-img li {
  margin-right: 20px;
}

/* 404 start  */
.error {
  text-align: center;
  width: 100%;
  padding: 70px 0;
}

.error-code {
  color: #234269;
  font-family: "Fjalla One", sans-serif;
  font-size: 200px;
  line-height: 1;
}
.error-message h2 {
  color: #444;
  display: inline-block;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.1;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0 15px;
}
#errorboxbody p {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 300;
  margin-bottom: 25px;
  margin-top: 0;
}
.button-home {
  background: linear-gradient(to bottom, #ff965c 0%, #f87932 100%);
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-size: 11px;
  font-weight: 400;
  height: 42px;
  letter-spacing: 1px;
  line-height: 42px;
  padding: 0 36px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}
a.button-home:hover {
  text-decoration: none;
  color: #000;
}
/* 404 end */

/* pagecenter start  */
.navbar {
  padding-left: 0;
  padding-right: 0;
}
.navbar-light .navbar-toggler {
  border-color: transparent;
}
.h_card .left_col img.card-img {
  max-width: 45px;
}

.slick-prev {
  left: 20px;
}
.slick-next {
  right: 20px;
}

.banner-slider.left ul.unstyle li sup {
  top: 5px;
  line-height: 1;
  font-size: 12px;
  vertical-align: top;
}
.video-react .video-react-poster {
  background-size: cover;
}

.privacypolicy .inner_wrap {
  box-shadow: 0px 5px 30px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 70px 60px;
  background: #fff;
}

.privacypolicy .alignment {
  margin-left: 39%;
}

.privacypolicy .alignment2 {
  margin-left: 42%;
}

.privacypolicy .alignment3 {
  margin-left: 40%;
}

.privacypolicy .alignment4 {
  margin-left: 2%;
}

.privacypolicy .logo {
  display: block;
  width: 200px;
  margin: 60px 0px;
}

.privacypolicy .copyright {
  font-size: 13px;
  text-align: center;
  margin-top: 60px;
}

.privacypolicy .fontSz {
  font-size: 19px;
}

.privacypolicy .heading {
  margin-left: 30px;
}
.privacypolicy .h4,
.privacypolicy h4 {
  font-size: 1.3rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.privacypolicy b,
.privacypolicy strong {
  font-weight: bolder;
}
.privacypolicy {
  color: #212529;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.fontSz {
  font-size: 19px;
}
.bodypadding {
  padding: 40px 0;
}
.errorpage_wrapper {
  height: calc(100vh - 250px);
}
.comingsoonwrapper {
  height: calc(100vh - 250px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.comingsoonwrapper > h1 {
  color: #fc4b4b;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
}
.comingsoonwrapper > h1:after {
  content: "";
  width: 600px;
  height: 220px;
  position: absolute;
  left: 50%;
  top: 50%;
  background: #eee;
  z-index: -1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
span.sup1 {
  position: relative;
  top: -1px;
  font-size: 16px;
}

span.sup2 {
  position: relative;
  top: -3px;
}
.privacypolicy a {
  color: #007bff;
}
.privacypolicy li {
  margin-bottom: 8px;
}

.download-links {
  border-radius: 9px;
  overflow: hidden;
}

/* Media Query start */
@media (min-width: 1181px) {
  .slick-active.slick-current + .slick-active + .slick-active img {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 1450px) and (min-width: 1000px) {
  .banner-slider.left ul.unstyle li h2 {
    padding: 0 0 0 70px;
    font-size: 45px;
  }
}

@media (max-width: 1270px) {
  .carousel_container {
    width: 100%;
  }
  .banner-right-sec img {
    max-width: 460px;
  }
  .banner-right-sec > div.slide1 {
    top: 0;
  }
  button.slick-arrow.slick-prev,
  button.slick-arrow.slick-next {
    width: 30px;
    height: 30px;
  }
  .slick-next {
    right: 5px;
  }
  .slick-prev {
    left: 5px;
  }
}

@media (max-width: 1180px) and (min-width: 861px) {
  .slick-active.slick-current + .slick-active img {
    opacity: 1;
    transform: scale(1);
  }
}
@media (max-width: 1080px) {
  div#navbarsExampleDefault li.nav-item {
    margin: 0;
  }
  div#navbarsExampleDefault a.nav-link {
    font-size: 14px;
  }
  .navbar-nav .nav-item {
    margin: 0 0;
  }
}

@media (max-width: 1040px) {
  .banner-slider.left ul.unstyle li i {
    padding: 0;
  }
  .banner-slider.left ul.unstyle li h1 {
    font-size: 35px;
    padding-left: 40px;
  }
  .banner-slider.left ul.unstyle li h2 {
    font-size: 40px;
    padding: 0 0 0 50px;
  }
  .banner-slider.left ul.unstyle .inline-img {
    margin-top: 0;
  }
  .banner-slider.left ul.unstyle ul.unstyle.inline-img {
    padding-left: 0;
  }
  .banner-slider.left ul.unstyle li {
    margin: 0 0px;
  }

  .banner-slider.left ul.unstyle .inline-img li img {
    max-width: 120px;
    object-fit: contain;
    vertical-align: top;
  }

  .banner-slider.left > ul.unstyle > li:first-child {
    margin-bottom: 30px;
  }

  .banner-slider.left > ul.unstyle > li {
    margin-bottom: 30px;
  }
  .banner-slider.center.text-center {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .banner-right-sec > div.slide2 {
    top: -90px;
  }

  .banner-right-sec > div.slide3 {
    top: -120px;
  }
  .custom_caption.p {
    padding: 0 20px;
  }
}

@media (max-width: 1325px) and (min-width: 992px) {
  .navbar-light .navbar-nav .nav-link {
    letter-spacing: 0;
    margin: 0 0.5em;
  }
  .navbar-brand img {
    max-width: 240px;
    width: 100%;
  }
}

@media (max-width: 960px) {
  .page_container {
    padding-right: 43px;
    padding-left: 43px;
  }
}
@media (min-width: 768px) {
  .banner-right-last img {
    margin-left: auto;
    margin-right: 16%;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .navbar-light .navbar-nav .nav-link {
    margin: 0;
  }
  .col-md-6.img img {
    max-width: 150px;
  }
  .banner-right-sec > div.slide2 {
    right: 90px;
    top: -30px;
  }
  .banner-right-sec > div.slide3 {
    top: -60px;
  }
  .navbar-nav {
    padding: 40px 0;
  }
  .banner-right-sec img {
    max-width: 340px !important;
  }
  .banner-right-sec > div.slide1 {
    top: -60px;
  }
}
@media (max-width: 860px) {
  .slick-active.slick-current img {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 767px) {
  .custom_caption.second_slide .banner-slider.left {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  .banner-slider.left > ul.unstyle > li:first-child {
    margin-bottom: 0px;
  }
  .privacypolicy .inner_wrap {
    padding: 70px 15px;
  }

  .privacypolicy .alignment {
    margin-left: 0;
  }

  .privacypolicy .alignment2 {
    margin: 0;
  }

  .privacypolicy .alignment3 {
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .privacypolicy .inner_wrap ul {
    padding-left: 20px;
  }
  .banner-slider.left ul.unstyle li sup {
    top: 3px;
    font-size: 8px;
    left: 2px;
  }
  .second_slide .banner-slider.left ul.unstyle li h2 {
    display: block;
  }

  .homeslider .banner-img.lst img {
    max-width: 140px;
  }
  .homeslider .banner-img.lst {
    background-color: #292c36;
  }
  .homeslider ul.slick-dots {
    bottom: 0;
  }
  .card_section {
    padding: 50px 0 0;
  }
  .h_card {
    margin-bottom: 30px;
  }
  .navbar-brand img {
    max-width: 190px;
  }
  .slick-next {
    right: 0;
  }
  .slick-prev {
    left: 0;
  }
  .navbar-light .navbar-nav .nav-link {
    margin: 0;
  }
  .page_container {
    padding-right: 30px;
    padding-left: 30px;
  }
  .video_section img {
    max-width: 280px;
  }
  .navbar-nav {
    padding: 40px 0;
  }
  .navbar-nav .nav-item {
    margin: 0;
  }

  .banner-slider.left ul.unstyle li {
    display: inline-block;
    margin: 0 10px 10px;
    vertical-align: top;
  }
  .banner-slider.left ul.unstyle {
    text-align: center;
  }
  .mobile_content .banner-slider.left ul.unstyle {
    text-align: left;
  }
  .banner-slider.left ul.unstyle li h1 {
    padding-left: 40px;
    padding-left: 0;
    font-size: 20px;
    padding-left: 0;
  }
  .banner-right-sec > div.slide1 {
    position: static;
  }
  .banner-right-sec img {
    max-width: 270px !important;
  }
  .banner-right-sec > div.slide2 {
    left: 25%;
    top: 40px;
  }

  .banner-right-sec > div.slide3 {
    top: 15px;
    left: 29%;
  }

  .banner-slider.left ul.unstyle li h2 {
    font-size: 22px;
    padding: 0;
  }
  .banner-slider.left ul.unstyle .inline-img {
    margin: 0;
  }

  .banner-slider.left ul.unstyle li ul li {
    margin: 0 10px 0 10px;
  }
  .banner-slider.left ul.unstyle li i {
    padding: 0;
  }
  .banner-slider.left ul.unstyle .inline-img li img {
    max-width: 77px;
  }

  .col-md-6.img img {
    max-width: 150px;
  }
  .banner-right-last {
    text-align: center;
    margin-top: 20px;
  }
  .video_section video {
    width: 300px;
    height: 300px;
    object-fit: cover;
  }

  .video_section {
    top: 46%;
  }
  .banner-img {
    height: 460px;
  }
  .custom_caption.carousel-caption {
    position: static;
    transform: none;
  }
  .mobile_content .custom_caption.carousel-caption {
    position: absolute;
    transform: translate(-50%, -50%);
  }
  .banner-slider.left {
    padding: 20px 0px 0;
    width: 100%;
  }
  .carousel-indicators li {
    width: 30px;
  }
  .video_section {
    left: 49%;
  }
  .carousel-indicators {
    margin-bottom: 20px;
  }
  .mb-md-3 {
    margin-bottom: 50px;
  }
  .phn_screen {
    transform: translate(-50%);
    left: 50%;
    margin-top: -11px;
    margin-left: 1px;
  }
  .card-deck {
    flex-flow: column wrap;
  }
  div#navbarsExampleDefault a.nav-link {
    font-size: 14px;
  }
  .card_section .card {
    margin-bottom: 30px;
  }
  .app_work_section .slide1 {
    padding: 0 25px;
  }
  .feature_section {
    padding: 10px 0;
  }
  .downloadapp_section {
    padding: 30px 0;
  }
  .banner-slider.left > ul.unstyle > li {
    display: flex;
    margin: 0;
    align-items: center;
    padding: 5px 0;
  }
  .banner-slider.left ul.unstyle > li > a {
    width: 50px;
    text-align: left;
  }
}

@media (max-width: 640px) {
  .content.first ul.unstyle {
    display: none;
  }
  .app_work_section .slide1 {
    padding: 0px 0;
  }
  .page_container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .banner-slider.left ul.unstyle li h2 {
    display: none;
  }
  .mobile_content .banner-slider.left ul.unstyle li h1 {
    display: block;
  }

  .mobile_content .banner-slider.left ul.unstyle li h2 {
    display: block;
  }

  .mobile_content .banner-slider.left ul.unstyle .inline-img li img {
    display: block;
  }
  .homeslider .banner-img.lst {
    background-position: top left -110px;
  }
}
@media (max-width: 576px) {
  .homeslider .banner-img.lst {
    background-position: top right -170px;
  }
  .error-code {
    font-size: 110px;
  }
  .banner-img.lst {
    background-position: right top;
  }
  button.slick-arrow.slick-prev,
  button.slick-arrow.slick-next {
    background-size: cover;
    width: 20px;
    height: 20px;
  }

  .phn_screen img {
    object-fit: cover;
    max-width: 240px;
  }
  .slick-slide img {
    max-width: 167px;
  }
  .screen-slide:before {
    height: 281px;
  }

  .banner-right-sec > div.slide1 {
    left: 6%;
  }

  .banner-right-sec > div.slide2 {
    left: 28%;
  }

  .banner-right-sec > div.slide3 {
    left: 50%;
  }
  img {
    max-width: 100%;
  }
}

/* home page end  */

.napdetect_videowrapper {
  position: relative;
  overflow: hidden;
}

.napdetect_videowrapper video#vid {
  width: 100%;
  height: 100%;
  margin-bottom: -6px;
}
.napdetectvideo_section {
  z-index: 0;
  width: 100%;
  height: 100%;
}
.icon-createac:before {
  content: "\e900";
}
.feature_section .card-deck img.card-img-top[src*="napdetect"] {
  height: 100px;
}
.feature_section .card-deck img.card-img-top[src*="ai"] {
  max-width: 62px;
}
.feature_section img.card-img-top {
  margin: 0 auto;
  width: 100%;
  max-width: 100px;
}
.feature_section p a {
  font-weight: bold;
}
.downloadapp_section h3.heading-loto sup {
  font-size: 10px;
  top: -14px;
  font-weight: bold;
  margin-left: 3px;
}
.font-f {
  font-family: Boxfont-Round;
  font-size: 30px;
  margin: 0 6px;
  font-weight: 400;
}
sup.tm {
  font-size: 9px;
  top: -16px;
  line-height: 1;
}

footer.footer_wrapper a {
  color: #fff;
}

.video-react .video-react-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slick-slide {
  position: relative;
}

footer.footer_wrapper .font_12 {
  font-size: 13px;
}

.homeslider .slick-dots li button:before {
  content: "";
  background: #fff;
  opacity: 1;
  height: 3px;
  width: 40px;
}
.homeslider ul.slick-dots li.slick-active button:before {
  background: red;
}
.homeslider .slick-dots li button {
  width: 40px;
  height: 20px;
}

.homeslider ul.slick-dots li {
  width: 40px;
}
p.card-text {
  font-size: 17px;
}
.card_section .card-deck,
.feature_section .card-deck {
  justify-content: space-around;
}
.card_section .card,
.feature_section .card {
  max-width: 580px;
}
.slidebox-inner .row {
  max-width: 2050px;
  margin: 0 auto;
}

@media (min-width: 2200px) {
  .banner-right-sec img {
    max-width: 920px;
  }
}

@media (max-width: 450px) {
  .slidebox-inner.middle_screen.mobile_content .banner-slider.left {
    display: flex;
    justify-content: center;
  }
  .banner-slider.left > ul.unstyle > li:first-child {
    margin-bottom: 0;
  }
  .banner-slider.left ul.unstyle li {
    margin-bottom: 0;
  }
  .mobile_content .custom_caption.carousel-caption {
    position: absolute;
    transform: translate(0%, 0%);
    left: 0;
    top: 0;
  }
  .homeslider .banner-img.lst {
    background-position: bottom -90px right -80px;
    background-repeat: no-repeat;
    background-color: #070e18;
  }
}
